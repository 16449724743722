// src/components/Products/ProductCard.js

import React, { useContext, useState } from 'react';
import { CartContext } from '../context/CartContext';
import '../../styles/Products/ProductCard.css';
import { toast } from 'react-toastify'; // Import toast

const ProductCard = ({ product, onRequestQuote }) => {
  const { addToCart } = useContext(CartContext);
  const [selectedFlavor, setSelectedFlavor] = useState(
    product.flavors ? product.flavors[0] : ''
  );
  const [selectedFilling, setSelectedFilling] = useState(
    product.fillings ? 'None' : ''
  );
  const [selectedButtercream, setSelectedButtercream] = useState(
    product.buttercreamFlavors ? product.buttercreamFlavors[0] : ''
  );
  const [selectedSize, setSelectedSize] = useState(
    product.sizes ? product.sizes[0] : null
  );

  const calculateDisplayedPrice = () => {
    let price = selectedSize ? selectedSize.price : product.price;

    // Add $10 if a filling other than "None" is selected
    if (selectedFilling && selectedFilling !== 'None') {
      price += 10;
    }

    return price;
  };

  const handleAddToCart = () => {
    let price = selectedSize ? selectedSize.price : product.price;
  
    // Add $10 if a filling other than "None" is selected
    if (selectedFilling && selectedFilling !== 'None') {
      price += 10;
    }
  
    const uniqueKey = `${product.id}-${selectedSize?.id || ''}-${selectedFlavor || ''}-${selectedFilling || ''}-${selectedButtercream || ''}`;
  
    // Create the item object to pass to addToCart
    const item = {
      id: product.id,
      category: product.category,
      name: product.name,
      price,
      quantity: 1,
      selectedSize: selectedSize ? { ...selectedSize } : null,
      selectedFlavor: selectedFlavor || 'N/A', // Ensure flavor is set
      selectedFilling: selectedFilling && selectedFilling !== '' ? selectedFilling : 'N/A', // Set to 'N/A' if empty
      selectedButtercream: selectedButtercream || 'Vanilla', // Default to 'Vanilla' if not set
      image: product.image,
      quoteOnly: product.quoteOnly,
      shippingCategory: product.shippingCategory, // Include shippingCategory
      restrictedToLocal:
        product.restrictedToLocal !== undefined
          ? product.restrictedToLocal
          : false,
      uniqueKey,
    };
  
    addToCart(item);
  
    // Display toast notification
    toast.success(`${product.name} added to cart!`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  
    console.log(
      `Added ${product.name} (${selectedSize?.size || 'N/A'}, ${selectedFlavor}, ${
        selectedFilling !== 'None' && selectedFilling !== '' ? selectedFilling : 'No Filling'
      }, Buttercream: ${selectedButtercream || 'Vanilla'}) to cart.`
    );
    console.log('Product Data in ProductCard:', product);
    console.log('Product restrictedToLocal:', product.restrictedToLocal); // Check product value
    console.log('CartItem restrictedToLocal:', item.restrictedToLocal);   // Check item value

  };

  // Function to determine if the product is quotable
  const isQuotable = () => {
    const quotableCategories = ['Cakes', 'Cupcakes'];
    return quotableCategories.includes(product.category);
  };

  return (
    <div className="product-card">
      {/* Product Image and Info */}
      <div className="card-image-wrapper">
        <img src={product.image} alt={product.name} />
      </div>
      <div className="product-info">
        <h3>{product.name}</h3>
        <p>{product.description}</p>

        {/* Size Selection */}
        {product.sizes && (
          <div className="size-selection">
            <label htmlFor={`size-${product.id}`}>Choose a size:</label>
            <select
              id={`size-${product.id}`}
              value={selectedSize ? selectedSize.id : ''}
              onChange={(e) => {
                const sizeId = parseInt(e.target.value);
                const size = product.sizes.find((s) => s.id === sizeId);
                setSelectedSize(size);
              }}
            >
              {product.sizes.map((size) => (
                <option key={size.id} value={size.id}>
                  {size.size} - ${size.price}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Flavor Selection */}
        {product.flavors && (
          <div className="flavor-selection">
            <label htmlFor={`flavor-${product.id}`}>Choose a flavor:</label>
            <select
              id={`flavor-${product.id}`}
              value={selectedFlavor}
              onChange={(e) => setSelectedFlavor(e.target.value)}
            >
              {product.flavors.map((flavor, index) => (
                <option key={index} value={flavor}>
                  {flavor}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Filling Selection */}
        {product.fillings && (
          <div className="filling-selection">
            <label htmlFor={`filling-${product.id}`}>Choose a filling (+$10):</label>
            <select
              name="filling"
              id={`filling-${product.id}`}
              value={selectedFilling}
              onChange={(e) => setSelectedFilling(e.target.value)}
            >
              {product.fillings.map((filling, index) => (
                <option key={index} value={filling}>
                  {filling}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Buttercream Selection */}
        {product.buttercreamFlavors && (
          <div className="buttercream-selection">
            <label htmlFor={`buttercream-${product.id}`}>Choose a buttercream flavor:</label>
            <select
              name='buttercream'
              id={`buttercream-${product.id}`}
              value={selectedButtercream}
              onChange={(e) => setSelectedButtercream(e.target.value)}
            >
              {product.buttercreamFlavors.map((flavor, index) => (
                <option key={index} value={flavor}>
                  {flavor}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Display Price */}
        <p className="price">${calculateDisplayedPrice().toFixed(2)}</p>

        {/* Conditional Rendering of Buttons */}
        {isQuotable() ? (
          // Quotable products have both buttons
          <div className="button-group">
            <button className="add-to-cart-button" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <button
              className="request-quote-button"
              onClick={() => onRequestQuote(product, selectedSize, selectedFlavor, selectedFilling, selectedButtercream)}
            >
              Request a Quote
            </button>
          </div>
        ) : (
          // Non-quotable products have only "Add to Cart" button
          <button className="add-to-cart-button" onClick={handleAddToCart}>
            Add to Cart
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
