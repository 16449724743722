// src/pages/Checkout.js

import React, { useEffect, useState, useContext } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { CartContext } from '../components/context/CartContext';
import { useNavigate } from 'react-router-dom';
import CartItem from '../components/CartItem';
import '../styles/Checkout.css';

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { cartItems, clearCart } = useContext(CartContext);
  const [clientSecret, setClientSecret] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  // State variables for prices
  const [subtotal, setSubtotal] = useState(0);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  // State variable for location error
  const [locationError, setLocationError] = useState('');

  // Define the convenience fee percentage
  const CONVENIENCE_FEE_PERCENTAGE = 0.03; // 3% fee

  // Define shipping costs
  const SHIPPING_COSTS = {
    local: 15, // NY and NJ
    mini: 15,
    regular: 25,
  };

  // Address state for collecting customer address
  const [address, setAddress] = useState({
    name: '',
    email: '',
    phone: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });

  // Handle address input changes
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = value;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: updatedValue,
    }));
  
    if (name === 'state') {
      const stateValue = updatedValue.toLowerCase();
      const validStates = ['ny', 'new york', 'nj', 'new jersey'];
      const isLocal = validStates.includes(stateValue);
  
      const hasRestrictedItems = cartItems.some((item) => item.restrictedToLocal);
  
      console.log('State:', stateValue, 'Is Local:', isLocal, 'Has Restricted Items:', hasRestrictedItems);
  
      if (!isLocal && hasRestrictedItems) {
        console.log('Setting locationError for restricted items and non-local state.');
        setLocationError(
          'Some items in your cart can only be shipped to NY and NJ. Please update your address or remove restricted items.'
        );
      } else {
        console.log('Clearing locationError.');
        setLocationError('');
      }
    }
  };

  // Validate location and update error message when cartItems or address.state changes
    useEffect(() => {
      // Skip validation if the state field is empty
      if (!address.state.trim()) {
        setLocationError('');
        return;
      }
    
      const stateValue = address.state.trim().toLowerCase();
      const validStates = ['ny', 'new york', 'nj', 'new jersey'];
      const isLocal = validStates.includes(stateValue);
    
      // Check for restricted items
      const hasRestrictedItems = cartItems.some((item) => item.restrictedToLocal);
    
      if (!isLocal && hasRestrictedItems) {
        setLocationError(
          'Some items in your cart can only be shipped to NY and NJ. Please update your address or remove restricted items.'
        );
        setShippingCost(0);
      } else {
        setLocationError('');
        // Calculate shipping cost
        if (isLocal) {
          setShippingCost(SHIPPING_COSTS.local);
        } else {
          const maxShippingCost = cartItems.reduce((maxCost, item) => {
            const itemShippingCategory = item.shippingCategory || 'regular';
            const itemShippingCost =
              SHIPPING_COSTS[itemShippingCategory] || SHIPPING_COSTS.regular;
            return Math.max(maxCost, itemShippingCost);
          }, 0);
          setShippingCost(maxShippingCost);
        }
      }
    }, [address.state, cartItems]);

  // Calculate subtotal, fees, and total price
  useEffect(() => {
    if (cartItems.length === 0) {
      navigate('/');
    } else {
      // Calculate subtotal
      const subtotalAmount = cartItems.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      setSubtotal(subtotalAmount);

      // Calculate convenience fee
      const fee = subtotalAmount * CONVENIENCE_FEE_PERCENTAGE;
      setConvenienceFee(fee);

      // Calculate total price
      const total = subtotalAmount + fee + shippingCost;
      setTotalPrice(total);
    }
  }, [cartItems, shippingCost, navigate]);

  // Create PaymentIntent when necessary
  useEffect(() => {
    if (cartItems.length === 0) return;
  
    if (!locationError && address.state) {
      fetch('/.netlify/functions/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ items: cartItems, state: address.state }),
      })
      .then((res) => res.json())
      .then((data) => {
        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        } else {
          console.error('Client Secret not found in response:', data);
        }
      })
      .catch((error) => {
        console.error('Error creating PaymentIntent:', error);
      });
    } else if (locationError) {
      // Only clear clientSecret if there's an actual locationError
      setClientSecret('');
    } else {
      // If the state is empty but no error, just don't fetch yet, but don't clear clientSecret either.
      // This way, once the user types a valid state, the fetch will run and fetch a new clientSecret.
    }
  }, [cartItems, address.state, locationError]);
  

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (locationError) {
      alert('Please correct the following issue:\n' + locationError);
      return;
    }

    setIsProcessing(true);

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: address.name,
          email: address.email,
          phone: address.phone,
          address: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postal_code: address.postal_code,
            country: address.country,
          },
        },
      },
      shipping: {
        name: address.name,
        phone: address.phone,
        address: {
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          postal_code: address.postal_code,
          country: address.country,
        },
      },
    });

    if (result.error) {
      console.error(result.error.message);
      alert(`Payment failed: ${result.error.message}`);
      setIsProcessing(false);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded!');

        // Prepare order details
        const orderDetails = {
          customerName: address.name,
          email: address.email,
          phone: address.phone || '',
          shippingAddress: `${address.line1}, ${address.line2}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country}`,
          items: cartItems,
          totalAmount: parseFloat(totalPrice.toFixed(2)), // Ensure it's a number
          paymentIntentId: result.paymentIntent.id,
        };

        // Save order to Airtable
        fetch('/.netlify/functions/save-order', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ orderDetails }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              clearCart();
              navigate('/success');
            } else {
              alert('There was an error saving your order. Please contact support.');
              setIsProcessing(false);
            }
          })
          .catch((error) => {
            console.error('Error saving order:', error);
            alert('There was an error saving your order. Please contact support.');
            setIsProcessing(false);
          });
      } else {
        setIsProcessing(false);
      }
    }
  };

  return (
    <div className="checkout-page">
      <div className="checkout-container">
        {/* Shipping Details Section */}
        <div className="shipping-details">
          <h3>Shipping Information</h3>
          <h1>We only ship mini-cupcakes, mini-pies, and regular pies nationwide. All other products are only delivered locally in the New York, Jersey City area!</h1>
          <p>
            All cakes and cupcakes will be delivered to your doorstep within 10 days. If an urgent or specific order is needed, put in a quote on the products page or contact Deshawn at info@deydeybakesllc.com
          </p>
          <p>
            Delivery fees vary based on the weight of the package that will be delivered/shipped from our location in Jersey City.  
          </p>
        </div>

        {/* Checkout Content */}
        <div className="checkout-content">
          <h2>Checkout</h2>
          <div className="cart-items">
            {cartItems.map((item) => (
              <CartItem key={item.uniqueKey} item={item} />
            ))}
          </div>
          {/* Display Subtotal, Shipping Cost, Convenience Fee, and Total Price */}
          <div className="price-summary">
            <h3>Subtotal: ${subtotal.toFixed(2)}</h3>
            <h3>Shipping Cost: ${shippingCost.toFixed(2)}</h3>
            <p>Based on USPS weight standards.</p>
            <h3>Convenience Fee (3%): ${convenienceFee.toFixed(2)}</h3>
            <h2>Total: ${totalPrice.toFixed(2)}</h2>
          </div>
          <form onSubmit={handleSubmit} className="checkout-form">
            {/* Address Fields */}
            <h3>Shipping Address</h3>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={address.name || ''}
              onChange={handleAddressChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={address.email || ''}
              onChange={handleAddressChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={address.phone || ''}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="line1"
              placeholder="Address Line 1"
              value={address.line1}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="line2"
              placeholder="Apt/PO. Box"
              value={address.line2}
              onChange={handleAddressChange}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={address.city}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={address.state}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="postal_code"
              placeholder="Postal Code"
              value={address.postal_code}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="country"
              placeholder="Country (US)"
              value={address.country}
              onChange={handleAddressChange}
              required
            />
            {/* Payment Details */}
            <CardElement className="card-element" />
            {locationError && <p className="error-message">{locationError}</p>}
            <div style={{ position: 'relative' }}>
            <button
              type="submit"
              disabled={!stripe || !clientSecret || isProcessing || locationError}
              className={`submit-button ${locationError ? 'disabled-button' : ''}`}
            >
              {isProcessing ? 'Processing...' : 'Pay Now'}
            </button>
            {/* Error message below the button */}
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
